@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Inter", sans-serif !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 44px !important;
}

.ant-select-single .ant-select-selector {
  align-items: center !important;
}

.ant-notification-notice-icon {
  top: 5px !important;
  left: 10px !important;
}

.ant-message-notice {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: left !important;
}

.ant-message-notice-content {
  padding: 10px 2rem !important;
}

/* Slide Fade Up */

.slide-fadein-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-fadein-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.4s;
}

.slide-fadein-up-exit {
  opacity: 1;
  transform: translateY(0);
}

.slide-fadein-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.4s;
}

/* Slide Fade Right */

.slide-fadein-right-enter {
  opacity: 0;
  transform: translateX(-20px);
}

.slide-fadein-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s, transform 0.4s;
}

.slide-fadein-right-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-fadein-right-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.3s, transform 0.4s;
}

/* Zoom Fade */

.zoom-fadein-enter {
  opacity: 0;
  transform: scale(0.99);
}

.zoom-fadein-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s;
}

.zoom-fadein-exit {
  opacity: 1;
  transform: scale(1);
}

.zoom-fadein-exit-active {
  opacity: 0;
  transform: scale(1.01);
  transition: opacity 0.3s, transform 0.3s;
}

/* Fade */

.fadein-enter {
  opacity: 0;
}

.fadein-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fadein-exit {
  opacity: 1;
}

.fadein-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all 300ms ease;
}

.menu-secondary-enter {
  opacity: 0;
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease;
}
.menu-secondary-exit {
  opacity: 1;
}
.menu-secondary-exit-active {
  opacity: 0;
  transform: translateX(110%);
  transition: all 300ms ease;
}
