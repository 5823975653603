.menuIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  cursor: pointer;
  border-radius: 4px;
  color: #4f4f4f;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #cee0fc;
    color: #0052cc !important;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #d0d5dd;
}

.input {
  margin-bottom: 1rem;
}

.upgradeButton {
  margin: 0 0.5rem !important;
  display: flex !important;
  align-items: center !important;

  svg {
    margin-right: 0.5rem;
  }
}

.logo {
  font-size: 32px;
  font-weight: 100;
  padding: 0 2rem 0 0.75rem;
}

.menuIcon {
  height: 20px;
  width: 20px;
}

.closeIcon {
  height: 20px;
  width: 20px;
}

.actionsContainer {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .actionButton {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #cee0fc;
    }
  }
}

.toolbarContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.menuContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.menuContainerToggled {
  z-index: 9999999;
  visibility: visible;
  opacity: 1;
}

.menuItem {
  padding: 0.75rem;
  border-radius: 4px;
  color: #4f4f4f !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #cee0fc;
    color: #0052cc !important;
  }
}
.myCompany {
  padding: 0.75rem;
  border-radius: 4px;
  color: #4f4f4f !important;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #cee0fc;
    color: #0052cc !important;
  }
}

.active {
  color: #0052cc !important;
}
