.container {
  height: 100%;
  display: flex;
  align-items: center;
  color: #4f4f4f !important;
  padding: 16px 0;
  /*  justify-content: space-between; */
  width: 100%;

  .logo {
    font-size: 32px;
    font-weight: 100;
    padding: 4px 2rem 0 0 !important;
    margin-left: 1rem !important;
  }

  .leftContainer {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .menuContainer {
    display: flex;
    align-items: center;

    .active {
      color: #0052cc !important;
      background-color: #cee0fc;
    }

    .menuItem {
      padding: 0 0.75rem;
      border-radius: 4px;
      height: 40px;
      color: #000000;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      margin: 0 0.2rem;
      font-weight: 400;

      svg {
        margin-right: 0.55rem;
        stroke: aquamarine !important;
      }

      &:hover {
        background-color: #e6f0ff;
        color: #000000 !important;

        svg{
          color: #0052cc;
        }
      }
    }
  }

  .HelpIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #e6f0ff;
    }
  }

  .toolbarContainer {
    display: flex;
    align-items: center;
    width: 30%;
    justify-content: flex-end;

    .upgradeButton {
      margin: 0 0.5rem !important;
      display: flex !important;
      align-items: center !important;
      height: 38px !important;
      color: #ffffff;
      background-color: #0052CC;
      border-radius: 6px;
      font-size: 0.75rem !important;

      svg {
        margin-right: 0.5rem;
        stroke: white;
      }

      &:hover {
        svg{
          stroke: #0052CC;
        }
      }
    }

    .actionsContainer {
      margin-left: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .actionButton {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        margin-left: 1rem;

        &:hover {
          background-color: #e6f0ff;
        }
      }
    }
  }
}

.userInfoContainer {
  height: 36px;
  width: 36px;
  background-color: #f9fafb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 2rem;

  &:hover {
    border: 4px solid #d9d9d9;
  }
}

.mobileSearch {
  display: none;
}

.input {
  width: 100%;
  height: 42px !important;
}

.dropdownMenuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropDownMenuIcon {
  margin:1px -.5rem 0 7px;
  svg{
    width: 9px !important;
    height: 9px !important;
  }
}


.dropdownMenuInfo {
  display: flex;
  align-items: center;
  svg {
    margin-right: 0.4rem;
  }
}

.dropdownMenuUser {
  display: flex;
  align-items: center;

  .name {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.7rem;
    background-color: #f9fafb;
    position: relative;

    .badge {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: green;
      position: absolute;
      top: 24px;
      left: 28px;
    }
  }

  .userName {
    font-weight: 500;
  }

  .email {
    font-size: 13px;
  }
}

@media (max-width: 991px) {
  .container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #4f4f4f !important;
    padding: 16px 0;
    width: 100%;

    .logo {
      font-size: 32px;
      font-weight: 100;
      padding: 0 2rem;
    }

    .leftContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100% !important;
    }
    .menuContainer {
      display: flex;

      .active {
        color: #0052cc !important;
      }

      .menuItem {
        padding: 0 0.75rem;
        border-radius: 4px;
        color: #4f4f4f;
        display: flex;
        align-items: center;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: #cee0fc;
          color: #0052cc !important;
        }
      }
      .myCompany {
        padding: 0.75rem;
        border-radius: 4px;
        color: #4f4f4f;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: #cee0fc;
          color: #0052cc !important;
        }
      }
    }

    .toolbarContainer {
      display: flex;
      align-items: center;
      width: 100% !important;

      .searchbarContainer {
        flex-grow: 1;
        margin: 0 0.5rem;
      }

      .upgradeButton {
        margin: 0 0.5rem !important;
        display: flex !important;
        align-items: center !important;
        height: 42px !important;

        svg {
          margin-right: 0.5rem;
        }
      }

      .actionsContainer {
        margin-left: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .actionButton {
          padding: 0.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            background-color: #cee0fc;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .logo {
    display: none;
  }
  .toolbarContainer {
    justify-content: center;
  }

  .leftContainer {
    justify-content: center !important;
  }

  .myCompany {
    min-width: 112px !important;
  }
}

@media (min-width: 992px) {
  .logo {
    font-size: 32px;
    font-weight: 100;
    padding: 4px 2rem 0 0 !important;
    margin-left: 1rem !important;
  }
}
