.AppSwitcherCard {
    position: absolute;
    right: 0;
    width: 300px;
    background-color: #ffffff;
    border-radius: 4px;
    border-bottom: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    padding: 0.5rem;
    margin-left: 0.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.2s ease-in-out;
    height: 100%;
}

.SwitchToButton{
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #000000   !important;
  margin-bottom: 1rem !important;
}

.AppSwitcherIcon {
  margin-top: 0.3rem;
  height: 20px;
  width: 20px;
  fill: #4a4a4a;
  transition: all 0.2s ease-in-out;
}

.AppSwitcherCardItemIcon {
  margin-top: 0.3rem;
  height: 20px;
  width: 20px;
  fill: #4a4a4a;
  transition: all 0.2s ease-in-out;
  float:left;
}

.AppSwitcherCardItemIcon img {
  margin-left: 0.2rem;
  margin-top: 0.35rem;
  height: 24px;
  width: 24px;
  transition: all 0.2s ease-in-out;
}


.AppSwitcherCardItem {
  display: flex;
  align-items: center;
  padding: 0.35rem 0.35rem 0 0.35rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    border-radius: 4px;
    background-color: #e6f0ff;
  }
}

.AppSwitcherCardItemText {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;
  margin-left: 2rem;
}

.AppSwitcherCardItemTextTitle {
  color: #000000 !important;
  font-size: 14px;
  font-weight: 500;
  margin-left: 0.5rem;
  transition: all 0.2s ease-in-out;
}

.AppSwitcherCardItemTextDescription {
  font-size: 12px;
  font-weight: 400;
  color: #4a4a4a;
  margin-left: 0.5rem;
  transition: all 0.2s ease-in-out;
}


.iconButton {
  margin-top:.3rem;
  &:hover {
    cursor: pointer;
  }

  &:active{
    background-color:#e6f0ff !important;
  }

}

.iconButtonBadge {
  position: absolute;
  font-size:8px;
  top: 0.4rem;
  right: 6rem;
  width: 15px;
  height: 15px;
  background: #e80808;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.dropdownAlerts{
  width: 300px !important;
}

.dropdownManage{
  width: 80px !important;
}

.dropdownManage > ul {
  padding: 0 0 5px 0 !important;
}

.notificationBadgeHeader{
  padding-left:1rem;
  padding-top:.5rem;
  margin-bottom:-1rem;
  color: #000000;

  .markAsReadButton{
    float:right;
    padding-right:1rem;
    font-size:11px;
    color: #0052CC;
    margin-top:3.2px
  }
}

.notificationIcon{
  &:active{
    background-color:#e6f0ff !important;
  }
}

.listMenu{
  padding-left: 1rem !important;
  margin-top:-0.3rem;
}

.logIcon{
  margin-right:0.6rem !important;

  svg{
    width:10px !important;
    padding-top: 0.15rem;
    color: #0052CC;
  }
}
.title{
  font-size:11px;
  font-weight: 400;
  color:#000000 !important;

  &:hover{
    color:#0052CC !important;
  }
}

.titleManage{
  font-size:9px;
  font-weight: 400;
  color:#000000 !important;
  padding-left: .8rem;

  &:hover{
    color:#0052CC !important;
  }
}


.itemDescription{
  font-size: 8px;
  padding-left: 1.25rem;
  margin-bottom: .1rem !important;
}

.itemDate{
  font-size: 9px;
  padding-right: 1rem;
}

.itemTag{
  font-size:7px !important;
  padding-right:-0.5rem;
}