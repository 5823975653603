.splashScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

.splashScreen .loadingDot {
  font-size: 100px;
  margin-top: -80px;
  position: relative;
  animation: ease infinite alternate;
  animation-name: run;
  animation-duration: 0.85s;
  color:#000000;
}

.logo{
  -webkit-mask: -webkit-gradient(radial, 17 17, 137, 17 17, 152, from(rgb(0, 0, 0)), color-stop(0.5, rgba(0, 0, 0, 0.2)), to(rgb(0, 0, 0)));
}

@keyframes run {
  0% {
    left: -90px;
    color: #eee;
  }
  50% {
    color: #666;
  }
  100% {
    left: 90px;
    color: #eee;
  }
}