.userAvatarContainer {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #f9fafb;
  color: #475467;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  cursor: pointer;

  &:hover{
    border: 3px solid #cee0fc;
  }
}

.userAvatarContainerActive {
  border: 3px solid #cee0fc;
}

.menuContainer {
  position: absolute;
  right: 0;
  top: 45px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid #eaecf0;
  width: 250px;
  box-shadow: 5px 5px 40px 10px rgba(0, 0, 0, 0.1);
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease-in-out;
}

.menuContainerOpen {
  z-index: 999999;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.divider {
  margin: 0 !important;
}

.userInfoContainer {
  padding: 1rem 1.2rem;
  display: flex;
  align-items: center;
}

.userAvatar {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #f9fafb;
  color: #475467;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo{
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.badge {
  height: 10px;
  width: 10px;
  background-color: #12b76a;
  border: 1.5px solid white;
  border-radius: 50%;
  position: absolute;
  top: 27px;
  left: 30px;
}

.userInfo{
  margin-left: 1rem;
}
.userName {
  font-weight: 500;
  color: #344054;
  font-size:14px;
  text-transform:capitalize;
}

.userEmail {
  font-size:12px;
  color: #667085;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 1rem;
  color: #344054 !important;
  cursor: pointer;

  &:hover {
    background-color: #e6f0ff;
    color: #000000 !important;
  }
}

.menuInfo {
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.7rem;
  }
}

.shortcut {
  color: #667085;
  font-size: 12px;
}

.logoutIcon {
  width: 16px;
  height: 16px;
}
