@plugin 'ThemeLoader';
apply('default');

[isly-theme="default"] {
    @import '~antd/lib/style/components.less';
    @import '~antd/lib/style/index.less';

    @font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    @switch-color: #0052cc;
    @kit-white     : #ffffff;
    @kit-black     : #161537;
    @kit-gray-1    : #f2f4f8;
    @kit-gray-2    : #e4e9f0;
    @kit-gray-3    : #dde2ec;
    @kit-gray-4    : #c3bedc;
    @kit-gray-6    : #667085;
    @kit-blue-light: #3d6ee7;
    @kit-red       : #f00;
    @kit-green     : #41b883;
    @kit-orange    : #faad15;
    @kit-purple: #542566;
    @kit-orange: #eb6023;
    @kit-yellow: #f39f1d;
    @kit-teal: #67c0b1;
    @kit-blue: #0052CC;
    @kit-violet: #68519d;
    /* @kit-white: #f8f7f7; */

    /* @font-size-base: 15px;
    @font-size-sm  : 13px;
    @font-size-lg  : 17px; */

    @text-color      : @kit-gray-6;
    @primary-color   : #2E3F76;
    @info-color      : @kit-blue;
    @success-color   : @kit-green;
    @processing-color: @kit-blue;
    @error-color     : @kit-red;
    @highlight-color : @kit-red;
    @warning-color   : @kit-orange;
    @normal-color    : @kit-gray-3;

    @body-background     : @kit-white;
    @component-background: @kit-white;
    @heading-color       : @kit-gray-6;

 /*    @layout-body-background  : @kit-purple;
    @layout-header-background: @kit-purple;
    @layout-footer-background: #0099cc;  */

    @border-color-base    : @kit-gray-2;
    @border-color-split   : @kit-gray-2;
    @border-color-inverse : @kit-white;
    @border-radius-base   : 4px;
    @disabled-color       : @kit-gray-4;
    @disabled-bg          : @kit-gray-2;

    @btn-primary-color: @kit-white;
    @btn-primary-bg   : #0052CC;
    @btn-default-color: @kit-gray-6;
    @btn-default-bg   : @kit-white;
    @btn-danger-color : @kit-white;
    @btn-danger-bg    : @kit-red;
    @btn-danger-border: @kit-red;

    @link-color       : @kit-blue;
    @link-hover-color : @kit-blue-light;
    @link-active-color: @kit-blue-light;

    @item-active-bg: @kit-gray-2;
    @item-hover-bg : @kit-gray-2;

    @input-placeholder-color: lighten(@kit-gray-6, 10%);
    @input-color            : @kit-gray-6;
    @input-addon-bg         : @kit-gray-1;
    @input-bg               : @kit-white;
    @input-border-color     : @kit-gray-2;

    @alert-success-border-color: #b7eb8f;
    @alert-success-bg-color    : #f6ffed;
    @alert-success-icon-color  : @kit-green;
    @alert-info-border-color   : #91d5ff;
    @alert-info-bg-color       : #e6f7ff;
    @alert-info-icon-color     : @kit-blue;
    @alert-warning-border-color: #ffe58f;
    @alert-warning-bg-color    : #fffbe6;
    @alert-warning-icon-color  : @kit-orange;
    @alert-error-border-color  : #ffa39e;
    @alert-error-bg-color      : #fff1f0;
    @alert-error-icon-color    : @kit-red;

    @tabs-hover-color      : @kit-blue-light;
    @tabs-active-color     : @kit-blue;
    @tabs-card-active-color: @kit-blue;
    @tabs-ink-bar-color    : @kit-blue;
    @tabs-highlight-color  : @kit-blue;

    @table-header-bg      : @kit-gray-1;
    @table-header-color   : @kit-gray-6;
    @table-footer-bg      : @kit-gray-1;
    @table-footer-color   : @kit-gray-6;
    @table-row-hover-bg   : @kit-gray-2;
    @table-selected-row-bg: @kit-gray-1;
    @table-expanded-row-bg: @kit-gray-1;

    @label-required-color: @kit-red;
    @label-color         : @kit-gray-6;

    @heading-color: #000;

    h2{
        margin-top: 0;
        margin-bottom: 0;
        color: #000;
        font-weight: 500;
    }

    p{
        margin-bottom:0 !important;
    }

    .ant-drawer-title{
        font-size: 14px;
    }

    .ant-form-item-label > label {
        color: black;
        position: relative;
        display: inline-flex;
        align-items: center;
        max-width: 100%;
        height: 32px;
        font-size: 14px;
    }

    .ant-btn > span{
        font-size:14px;
    }

    .ant-form-item-label > label .ant-form-item-tooltip{
        color: black;
        cursor: help;
        writing-mode: horizontal-tb;
    }

    .ub-z-idx_20{
        z-index: 999 !important;
    }

}
@primary-color: #776655;