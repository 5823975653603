.HelpButtonCard {
    position: absolute;
    right: 0;
    width: 220px;
    background-color: #ffffff;
    border-radius: 4px;
    border-bottom: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 0.2s ease-in-out;
    height: 100%;
}

.HelpButtonCard span {
  margin-bottom:1rem !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #667085;
}

.HelpIcon {
  margin-top: 0.3rem;
  height: 24px;
  width: 24px;
  transition: all 0.2s ease-in-out;
}

.menuContainer{
    padding: 0.5rem 0rem 0rem 0rem;
}

.menuItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 0rem 0.5rem 0.2rem;
  color: #000000 !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

}

.menuItem:hover {
  background-color: #f5f7fa;
  width: 100%;
}

.menuInfo {
  display: flex;
  align-items: center;
}

.menuInfo span {
  margin-left: 0.5rem;
}


.shortCut {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}