
.container {

  .headerContainer {
    position: fixed;
    top:0;
    width:100%;
    background-color: white !important;
    z-index: 999;
    height: 56px !important;
    border-bottom: 1px solid #d0d5dd;
    padding: 0 1.5rem;
    display: flex;
    align-items: center;
  }

  .contentContainer {
    //padding: 2.5rem 5rem;
    margin-top:3.5rem;
  }
}

.logo {
  font-size: 32px;
  font-weight: 100;
  padding: 4px 1rem 0 0 !important;
  margin-left: 1rem !important;
}

.logo img {
  width: 150px;
  margin: 0.8rem 0 1rem 0;
}

.mobileHeader {
  display: none;
}

.header {
  flex-grow: 1;
}

@media (max-width: 1090px) {
  .headerContainer {
    height: fit-content !important;
  }

  .header {
    display: none;
  }

  .mobileHeader {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    height: 100%;
    padding: 16px 0;
  }
}

@media (min-width: 1500px) {
  /*  .headerContainer {
    padding: 0 15rem !important;
  } */

  .contentContainer {
  }

  .logo {
    font-size: 32px;
    font-weight: 100;
    padding: 4px 1rem 0 0 !important;
    margin-left: 1rem !important;
  }
}
